export function sendEmail(clientId, emailType, customerName, phone, caseNumber, model, caseDate, url, address, claimNumber, claimSetupDate, serialNumber) {
    console.log(clientId, customerName, phone, caseNumber, model, caseDate, url, address, claimNumber, claimSetupDate, serialNumber);

    // Send Approved Mail
    return fetch('communication/'.concat(clientId), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            emailModel: {

                Recipients: [address],
                Cc: [],
                Bcc: [],
                IsBodyHtml: true,
                emailType: emailType // Int
            },
            serviceModel: {
                ClaimNumber: claimNumber,
                ConsumerName: customerName,
                SubscriberName: customerName, // First Name + Last Name
                MiniSiteURL: url, // '<a href="https://www.aigtheftandloss.com">www.aigtheftandloss.com</a>'
                WirelessNumber: phone, //this.props.party.partyPhoneModel[0].number
                DeviceMakeandModel: model,//this.props.contract.contractAssetModel[0].modelNumber
                CaseNumber: caseNumber,//this.props.clientClaimNumber
                CaseNumberDate: caseDate, //moment(this.props.claimIncidentDate).format("MM/DD/YYYY")
                ClaimSetupDate: claimSetupDate,
                SerialNumber: serialNumber
            }
        })
    }).then(function (res) {
        let statusCode = res.status;
        return res.json();
    })
}

export async function getContractByContractNumber(contractNumber) {
    const res = await fetch(`contract/GetContractByContractNumber/${contractNumber}/default`);
    if (res.ok) {
        if (res.status === 204) {
            return null;
        }

        const data = res.json();
        return data;
    }

    return null;
}

//export const dataLayerPush=(pagePath, pageTitle, data = {})=> {
//    //console.log('=== data ===', pageTitle, data)
//    window.dataLayer = window.dataLayer || [];
//    window.dataLayer.push({
//        event: 'Pageview',
//        pagePath,
//        pageTitle,
//        ...data
//    });
//}

export const dataLayerPush = (event, data = {}) => {
    // console.log('=== data ===', data, event)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event,
        ...data
    });
}

export function SendMail(clientId, email, service) {
    fetch(`Communication/${clientId}`,
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                emailModel: {
                    recipients: email.recipients,
                    cc: email.cc,
                    bcc: email.bcc,
                    isBodyHtml: email.isBodyHtml,
                    emailType: email.emailType
                },
                serviceModel: {
                    consumerName: service.consumerName,
                    fee: service.fee,
                    appointmentDate: service.appointmentDate,
                    appointmentWindow: service.appointmentWindow,
                    servicer: service.servicer,
                    servicerPhone: service.servicerPhone,
                    claimNumber: service.claimNumber,
                    subscriberName: service.subscriberName,
                    miniSiteURL: service.miniSiteURL,
                    wirelessNumber: service.wirelessNumber,
                    deviceMakeandModel: service.deviceMakeandModel,
                    caseNumber: service.caseNumber,
                    caseNumberDate: service.caseNumberDate,
                    firstName: service.firstName,
                    lastName: service.lastName,
                    serialNumber: service.serialNumber,
                    documentDeliveryEmailAddress: service.documentDeliveryEmailAddress,
                    documentDeliveryMailAddress: service.documentDeliveryMailAddress,
                    callCenterTollFreeNo: service.callCenterTollFreeNo,
                    documentRequired: service.documentRequired,
                    claimSetupDate: service.claimSetupDate,
                    programName: service.programName,
                    programPhoneNumber: service.programPhoneNumber,
                    contractList: service.contractList,
                    totalAmt: service.totalAmt,
                    clientId: service.clientId,
                    laborReimbursement: service.laborReimbursement,
                    type: service.type,
                    term: service.term,
                    startDate: service.startDate,
                    endDate: service.endDate,
                    paymentMethod: service.paymentMethod,
                    reportsLink: service.reportsLink,
                    partyId: service.partyId,
                    companyName: service.companyName,
                    vendorId: service.vendorId    
                }
            })
        }).then(res => {
            return res;
        })
}

export function SendMailByAccount(clientId, email, service, accountId, type, tokenInfo = null) {

    fetch(`Communication/SendEMailByAccountIdAndType/${clientId}/${accountId}/${type}`,
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                emailModel: {
                    recipients: email.recipients,
                    cc: email.cc,
                    bcc: email.bcc,
                    isBodyHtml: email.isBodyHtml,
                    emailType: email.emailType
                },
                serviceModel: {
                    consumerName: service.consumerName,
                    fee: service.fee,
                    appointmentDate: service.appointmentDate,
                    appointmentWindow: service.appointmentWindow,
                    servicer: service.servicer,
                    servicerPhone: service.servicerPhone,
                    claimNumber: service.claimNumber,
                    subscriberName: service.subscriberName,
                    miniSiteURL: service.miniSiteURL,
                    wirelessNumber: service.wirelessNumber,
                    deviceMakeandModel: service.deviceMakeandModel,
                    caseNumber: service.caseNumber,
                    caseNumberDate: service.caseNumberDate,
                    firstName: service.firstName,
                    lastName: service.lastName,
                    serialNumber: service.serialNumber,
                    documentDeliveryEmailAddress: service.documentDeliveryEmailAddress,
                    documentDeliveryMailAddress: service.documentDeliveryMailAddress,
                    callCenterTollFreeNo: service.callCenterTollFreeNo,
                    documentRequired: service.documentRequired,
                    claimSetupDate: service.claimSetupDate,
                    programName: service.programName,
                    programPhoneNumber: service.programPhoneNumber,
                    contractList: service.contractList,
                    totalAmt: service.totalAmt,
                    clientId: service.clientId,
                    laborReimbursement: service.laborReimbursement,
                    type: service.type,
                    term: service.term,
                    startDate: service.startDate,
                    endDate: service.endDate,
                    paymentMethod: service.paymentMethod,
                    reportsLink: service.reportsLink,
                    advantageAccountName: service.advantageAccountName,
                    partyId: service.partyId,
                    totalAmtText: service.totalAmtText,
                    languageCode: service.languageCode,
                },
                tokenURLRequestModel: {
                    tokenTypeId: tokenInfo?.tokenTypeId,
                    target: tokenInfo?.target,
                    accountId: tokenInfo?.accountId,
                    dealerId: tokenInfo?.dealerId
                }
            })
        }).then(res => {
            let statusCode = res.status;
            return res.json();
        })
}


export function emailModel(recipients = [], cc = [], bcc = [], isBodyHtml = true, emailType = 1) {
    this.recipients = recipients;
    this.cc = cc;
    this.bcc = bcc;
    this.isBodyHtml = isBodyHtml;
    this.emailType = emailType;
}

export function serviceModel(consumerName = "", fee = "", appointmentDate = "", appointmentWindow = "", servicer = "", servicerPhone = "", claimNumber = "", subscriberName = "", miniSiteURL = "", wirelessNumber = "",
    deviceMakeandModel = "", caseNumber = "", caseNumberDate = "", firstName = "", lastName = "", serialNumber = "", documentDeliveryEmailAddress = "", documentDeliveryMailAddress = "", callCenterTollFreeNo = "", documentRequired = "",
    claimSetupDate = "", programName = "", programPhoneNumber = "", contractList = "", totalAmt = 0, clientId = "",
    laborReimbursement = "", type = "", term = "", startDate = "", endDate = "", paymentMethod = "", reportsLink = "", advantageAccountName = "", partyId = null, totalAmtText = "", companyName = "", vendorId = "", languageCode = "") {
    this.consumerName = consumerName;
    this.fee = fee;
    this.appointmentDate = appointmentDate;
    this.appointmentWindow = appointmentWindow;
    this.servicer = servicer;
    this.servicerPhone = servicerPhone;
    this.claimNumber = claimNumber;
    this.subscriberName = subscriberName;
    this.miniSiteURL = miniSiteURL;
    this.wirelessNumber = wirelessNumber; //
    this.deviceMakeandModel = deviceMakeandModel;
    this.caseNumber = caseNumber;
    this.caseNumberDate = caseNumberDate;
    this.firstName = firstName;
    this.lastName = lastName;
    this.serialNumber = serialNumber;
    this.documentDeliveryEmailAddress = documentDeliveryEmailAddress;
    this.documentDeliveryMailAddress = documentDeliveryMailAddress;
    this.callCenterTollFreeNo = callCenterTollFreeNo; //
    this.documentRequired = documentRequired;
    this.claimSetupDate = claimSetupDate;
    this.programName = programName;
    this.programPhoneNumber = programPhoneNumber;
    this.contractList = contractList;
    this.totalAmt = totalAmt;
    this.clientId = "HVAC";
    this.laborReimbursement = laborReimbursement;
    this.type = type;
    this.term = term;
    this.startDate = startDate;
    this.endDate = endDate;
    this.paymentMethod = paymentMethod;
    this.reportsLinks = reportsLink;
    this.advantageAccountName = advantageAccountName;
    this.partyId = partyId;
    this.totalAmtText = totalAmtText;
    this.companyName = companyName;
    this.vendorId = vendorId;
    this.languageCode = languageCode;
}


export const getPrimaryEmail = (dealerProfile) => {
    var res = "";

    if (dealerProfile.contacts !== undefined) {
        var primaryContact = dealerProfile.contacts.find(x => x.isPrimary === true);

        if (primaryContact !== undefined) {
            res = primaryContact.email;
        }
        else {
            res = dealerProfile.emailAddress;
        }
    }

    return res;
}

export const getFeatureFlags = async () => {
  const res = await fetch('helper/getfeatureflags');

  if (res.status === 200) {
    const client = await res.json();
    return client.featureFlags;
  }
  else {
    return null;
  }
}

export const getEmailTypes = async (accountName) => {
    const res = await fetch(`helper/GetEmailTypes/${accountName}`);

    if (res.status === 200) {
        const client = await res.json();
        return client.emailTypes;
    }
    else {
        return null;
    }
}

export async function getTranslation(path, language="en") {
    var route = "dynamicContent/text";
    return await fetch(route, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Path: path ?? '',
            //language is currently not used, may be used in later stories
            Language: language ?? ''
        })
    });
}