import React, { useContext, Component, useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Progress, Row, Col, Label } from "reactstrap";
import ReplacementDetails from '../../InvoiceTables/ReplacementDetails';
import { SessionContext } from '../../../context/SessionContext';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as Paper } from '../../../assets/images/paper.svg';
import Dropzone from '../../UploadDocs/DragandDropFileUpload';
import styles from '../RequestProductUpdate/UpdateRequest.module.css';
import { useClaimInvoiceStore } from '../../InvoiceTables/lib/state';
import SnackBarAlert from "../../shared/SnackBar/SnackBarAlert";

export const EditClaimModal = ({ isOpen, handleSubmit,  centered, onEdit, row }) => {
    const { getProfile } = useContext(SessionContext)
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState(null);
    const [invalidDoc, setInvalidDoc] = useState(false)
    const [btnDisable, setBtnDisable] = useState(true);
    const [errors, setErrors] = useState(true);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [fileUploadPercent, setFileUploadPercent] = useState();
    const store = useClaimInvoiceStore(state => state);
    const isUnitReplacedSelected = row?.repair?.selected?.[0]?.value === 'unitreplaced';
    const staticFlag = row?.replacementDetails ? true : false;

    const formProps = useForm({
        mode: 'onChange',
        shouldUnregister: true,
        defaultValues: {}
    });

    useEffect(() => {
        if (row?.replacementDetails?.documents) {
            setFile(row?.replacementDetails?.documents ?? [])
            setFileUploadPercent(100)
        }
    }, [row])

    const handleRemoveFile = (row) => {
        setFile(file.filter((_, i) => i !== row));
        const cnt = file.filter((_, i) => i !== row)
        if (cnt.length == 0) {
            setInvalidDoc(false)
            setBtnDisable(true);
        }
    }

    const handleFileUpload = (fileData) => {
        if (fileData && fileData.length > 0) {
            if (checkTotalBytes(fileData) == false) {
                setInvalidDoc(true);
            } else {
                const currentFile = fileData[0];
                setFileName(currentFile.name);
                setFile(file.concat(fileData));
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setFile(file.concat({
                        ...fileData,
                        selectedDoc: reader.result
                    }));
                   
                }, false)
                reader.addEventListener("abort", (ev) => {
                }, false)
                reader.addEventListener("error", (ev) => {
                }, false)
                reader.addEventListener("progress", (event) => {
                    setFileUploadPercent(
                        Math.round((event.total / event.loaded) * 100)
                    );
                })
                reader.readAsDataURL(currentFile);
                setInvalidDoc(false);
                setBtnDisable(false);
                setErrors({
                    ...errors,
                    fileError: false
                })
            }
        }
        else {
            setInvalidDoc(true);
        }
    }

    const checkTotalBytes = (currentFile) => {
        if (currentFile.length > 1) {
            // handle multiple files at once
            var totalPerFile = 0
            for (var i = 0; i < currentFile.length; i++) {
                var filesByte = currentFile[i].size
                for (var ii = 0; ii < file.length; ii++) {
                    totalPerFile = totalPerFile + file[ii].size;
                }
                totalPerFile = totalPerFile + filesByte;
            }

            if (totalPerFile <= 4194304) {
                return true
            } else {
                return false
            }

        } else {
            var fileByte = currentFile[0].size
            var total = 0
            for (var index = 0; index < file.length; index++) {
                total = total + file[index].size;
            }
            total = total + fileByte;

            if (total <= 4194304) {
                return true
            } else {
                return false
            }
        }
    }

    const {
        formState: { isDirty, isValid },
    } = formProps;

    const onSubmit = (data) => {
        if (file.length <= 0) {
            setErrors({
                ...errors,
                fileError: true
            })
            return;
        }

        store.setReplacementDetails({
            ...data,
            ...row,
            file
        })
        handleSubmit();
        setShowSnackBar(true);
    }

    return (
        <>
            <Modal isOpen={isOpen}
                className="deleteCard"
                centered={centered}>
                <FormProvider {...formProps}>
                    <form key="claim-modal-form" onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        formProps.handleSubmit(onSubmit)();
                    }
                    }>
                        <div className={styles.deleteCardHeader}>
                            <h4>{isUnitReplacedSelected ? 'Replacement Asset Details' : 'Asset Details'}</h4>
                        </div>
                        <ModalBody>
                            <ReplacementDetails dealerProfile={getProfile()} row={row} className="mt-3" formProps={formProps} />
                            <div className={`${staticFlag ? styles.staticDropZone : ''}`}> 
                            <p htmlFor="documentUpload">To process your request, we need you to upload the installation invoice.</p>
                            
                            <Dropzone onFileUpload={handleFileUpload} />
                            {file?.map((f, key) => (
                                <div className={styles.uploadProgress}>
                                    <Paper className={styles.file} />
                                    <div className={styles.progressWrapper}>
                                        <div key={key} id={key} className={styles.fileName}>{f?.[0]?.name || f?.documentName}</div>
                                        <div className={styles.uploadProgressWrapper}>
                                            <Progress className={styles.progress} value={fileUploadPercent} />
                                            <button id={"remove-btn" + key} onClick={() => handleRemoveFile(key)} aria-label="Cancel Uploaded File" className={styles.closeButton}></button>
                                        </div>
                                        <div className={styles.uploadPercentage}>upload <span>{fileUploadPercent}% completed</span></div>
                                    </div>
                                </div>
                            ))}
                            {errors.fileError && <p className="errorMsg">Please upload the document.</p>}
                            {invalidDoc &&
                                <p className="errorMsg">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf).</p>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                className="btnLink"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowSnackBar(false)
                                    onEdit();
                                }}>
                                Cancel
                            </button>
                            <button className="btn-primary" disabled={staticFlag} type="submit">Save</button>
                        </ModalFooter>
                    </form>
                </FormProvider>
            </Modal>
            {showSnackBar &&
                <SnackBarAlert
                    content={'Asset details added to claim'}
                    showSnackBar={true}
                    onClose={() => setShowSnackBar(false)}
                />
            }
        </>
    );
};
